<template>
    <component :is="SkinComponent" v-model="form" v-bind="{ code, loading }" v-on="{ save }" />
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { initForm } from "@/assets/variables";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
        category: { type: String, default: null },
    },

    data: () => ({
        form: initForm(),

        loading: false,
    }),

    computed: {
        ...mapState(["accessToken"]),
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
    },

    mounted() {
        this.init();
    },

    watch: {
        accessToken() {
            this.init();
        },
    },

    methods: {
        async init() {
            const { code, category } = this;

            if (this.accessToken) {
                const { user: writer } = await api.v1.me.get();
                const { name, phone, email, postcode, address1, address2 } = writer;
                this.form = initForm({
                    ...this.form,
                    code,
                    category,
                    writer,
                    meta: { ...this.form.meta, name, phone, email, postcode, address1, address2 },
                });
            } else this.form = initForm({ ...this.form, code, category });
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { _files = [], files = [], ...form } = this.form;
                form = (await api.v1.forms.post(form))?.form;

                for (const [index, file] of files.entries()) {
                    files[index] = (await api.v1.files.post({ path: "forms", _index: form?._id, index }, file))?.file;
                }
                _files = files.map(({ _id }) => _id);

                form = await api.v1.forms.put({ _id: form._id, _files });

                alert("저장되었습니다");

                this.$router.go(-1);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
